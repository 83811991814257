import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    places: [{
                    name: 'Dyno',
                    lat: '59.7368856',
                    lon: '10.293421',
                    windDirection: {
                        'good': ['260 < wind', 'wind < 90'],
                        'bad': ['90 < wind <= 260']
                    },
                    now: null,
                    hour1: null,
                    hour2: null,
                    firewood: true
                },
                {
                    name: 'Deildåsen',
                    lat: '59.4785257',
                    lon: '7.4340446',
                    windDirection: {
                        'good': ['260 < wind', 'wind < 90'],
                        'bad': ['90 < wind <= 260']
                    },
                    now: null,
                    hour1: null,
                    hour2: null,
                    firewood: true
                },
                {
                    name: 'Engersand friluftsområdet',
                    lat: '59.7268921',
                    lon: '10.3025738',
                    windDirection: {
                        'good': ['260 < wind', 'wind < 90'],
                        'bad': ['90 < wind <= 260']
                    },
                    now: null,
                    hour1: null,
                    hour2: null,
                    firewood: true
                },
            ],
  },
  mutations: {
  },
  getters: {
    places: state => {
      return state.places
    }
  }
})

export default store